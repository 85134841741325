import { MailchimpSignupFields } from './mailchimp-signup';
import {LitElement, html } from 'lit'
import {customElement, property, query, queryAssignedNodes, state} from 'lit/decorators.js'
import { ifDefined } from 'lit/directives/if-defined';
import { repeat } from 'lit/directives/repeat.js';

export type HTMLInputAttribute = "button" | "checkbox" | "color" | "date" | "datetime-local" | "email" | "file" | "hidden" | "image" | "month" | "number" | "password" | "radio" | "range" | "reset" | "search" | "submit" | "tel" | "text" | "time" | "url" | "week"

export interface MailchimpSignupField {
  id: string
  type?: HTMLInputAttribute
  label?: string
  placeholder?: string
  required?: boolean
  value?: string
}

/**
 * Mailchimp signup form.
 * @element mailchimp-signup
 * @fires success - When the form is successfully submitted.
 *
 * See: https://mailchimp.com/en-gb/help/host-your-own-signup-forms for getting IDs
 */
@customElement('mailchimp-signup')
export class MailchimpSignup extends LitElement {
  @property()
  url = ''

  @property()
  email = ''

  @property()
  placeholder = "Please enter your placeholder"

  @property()
  button = "Subscribe"

  get urlObject() {
    return new URL(this.url);
  }

  get user() {
    return this.urlObject.searchParams.get('u')
  }

  get audience() {
    return this.urlObject.searchParams.get('id')
  }

  get fields(): MailchimpSignupFields[] {
    return [
      {
        id: 'EMAIL',
        type: 'email',
        placeholder: this.placeholder,
        required: true
      },
    ];
  }

  renderInputRow({ id, type, label, placeholder, value, required }: MailchimpSignupField) {
    if (!type)
      type = 'text';

    if (!label)
      label = id;

    const inputId = `mce-${id}`;

    const classes = [
      'input',
      type,
      required ? 'required' : '',
    ].join(" ")

    return html`
      <label for="${inputId}" class="label" part="label ${id}">${label}</label>
      <input type="${type}" name="${id}" id="${inputId}" placeholder="${ifDefined(placeholder)}" ?required="${required}" part="${classes}" value="${ifDefined(value)}">`
  }

  get honeypot_id() {
    return `b_${this.user}_${this.audience}`;
  }

  protected render() {
    if (!this.url) {
      console.error("MailchimpSignup: url is required");
      return html`<!-- Error showing MailchimpSignup-->`;
    }

    return html`
      <form action="${this.url}" method="post" part="form">
        <slot name="header" class="header" part="header">
          <h3 part="header-title">Sign up to our mailing list</h3>
        </slot>

        <div part="input-group">
          ${repeat(
            this.fields,
            (field) => field.id,
            this.renderInputRow
          )}

          <div style="position: absolute; left: -5000px;" aria-hidden="true">
            <input type="text" name="${this.honeypot_id}" tabindex="-1" value="">
          </div>
          <button type="submit" part="button submit">${this.button}</button>
        </div>
      </form>
    `;
  }
}
